<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="pt-8"
          title="UN Friction Test – Test 3 (b) (iv)"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="ABL Friction Machine Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/ABLFrictionDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ABLFrictionDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to measure the sensitiveness of the substance to
              friction stimuli and to determine if the substance is unstable in
              the form it was tested. The test substance is subjected to a
              vertical compression force under a non-rotating wheel, while the
              substance is moved in a horizontal direction on a sliding anvil.
              It is applicable to solid, semisolid, and powder substances.
            </p>
            <TitleComponent
              class="pt-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are four different friction sensitivity tests in the UN
              Manual of Tests and criteria. See the UN Manual of Tests and
              Criteria for further details.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <p class="p-light mb-4">
              Sample conditionRepeatably obtain results for a given sample
              Substances are tested in the form received with wetted substances
              tested with the minimum quantity of wetting agent required for
              transport. Solid samples are cut to a thickness of 0.08 ± 0.01 cm
              (0.033 ± 0.004 in).
            </p>
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Force applied to sample</td>
                    <td>Force applied to determine the sample’s response</td>
                    <td>
                      The force applied was chosen to yield at least 1 in 6
                      reactions when testing dry PETN but not with dry RDX as
                      reported in the UN Manual of Tests and Criteria for tests
                      with the BAM friction.
                    </td>
                    <td>
                      The force used for testing is 56 lbf (250 N) at a velocity
                      of 8 ft/s (2.4 m/s) or 100 lbf (445 N) at a velocity of 4
                      ft/s (1.2 m/s). Note that the force on the sample is
                      applied through a hydraulic ram typically 1-inch in
                      diameter so applying a 56 lbf (250 N) or 100 lbf (445 N)
                      force would correlate to a pressure of 70 psi (495 kPa)
                      and 125 psi (880 kPa) respectively.
                    </td>
                  </tr>
                  <tr>
                    <td>Slider or Anvil speed</td>
                    <td>
                      Provide a uniform and repeatable speed for each trial that
                      can be related to in-process conditions of the friction
                      insult
                    </td>
                    <td></td>
                    <td>8 ft/sec (2.4 m/sec) or 4 ft/sec (1.2 m/s)</td>
                  </tr>
                  <tr>
                    <td>Hydraulic ram</td>
                    <td>
                      Consistent loading of sample beneath the stationary wheel
                      throughout the test
                    </td>
                    <td></td>
                    <td>Ram diameter is typically 1 inch (2.54 cm)</td>
                  </tr>
                  <tr>
                    <td>Thickness of friction wheel</td>
                    <td>
                      Strongly influences the amount of pressure applied to the
                      sample; must be of sufficient thickness to resist the test
                      loads without structural deformation
                    </td>
                    <td></td>
                    <td>Standardized to 0.125 in (0.318 cm)</td>
                  </tr>
                  <tr>
                    <td>Surface finish of friction wheel and anvil</td>
                    <td>Provide a consistent friction insult per area</td>
                    <td>
                      MIL-STD-1751A Method 1021 references 63 µin for a surface
                      finish for the ABL Friction test surfaces
                    </td>
                    <td>
                      Standardized to 1.3 – 1.8 µm (50 – 70 µin)
                      <a
                        class="link"
                        href="https://www.etusersgroup.org/library/equipment-standards-procedures"
                        target="_blank"
                        rel="noopener noreferrer"
                        >There are standardized ABL Friction Wheel and Anvil
                        Surfacing Procedures</a
                      >.
                    </td>
                  </tr>
                  <tr>
                    <td>Material and hardness of friction wheel and anvil</td>
                    <td>Provide a standardized material and hardness</td>
                    <td>
                      Material and hardness of common process equipment tooling
                    </td>
                    <td>
                      Standardized as MGR tool steel (A8) with a hardness of
                      Rockwell “C” 55 – 62
                    </td>
                  </tr>
                  <tr>
                    <td>Parallelism of friction wheel and anvil</td>
                    <td>
                      Provide a flat, even contact area for a uniform friction
                      insult that can be related to in-process conditions
                    </td>
                    <td>
                      A contact pattern other than square could result in a
                      localized high friction insult being applied to the sample
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Flatness of the friction anvil</td>
                    <td>
                      Provide a uniform surface so additional friction forces
                      are not introduced by surface inconsistencies
                    </td>
                    <td></td>
                    <td>Standardized to ≤0.002 in (0.005 cm)</td>
                  </tr>
                  <tr>
                    <td>Sample thickness</td>
                    <td>Repeatably test a standardize thickness</td>
                    <td></td>
                    <td>
                      Standardized to a monolayer of material for powders;
                      thickness of solid slices are 0.033 ± 0.004 in (0.08 ±
                      0.01 cm); semisolids are 0.006 in (0.015 cm) thick
                    </td>
                  </tr>
                  <tr>
                    <td>Sample quantity or length</td>
                    <td>
                      Provide sufficient sample to cover the area directly under
                      the wheel
                    </td>
                    <td></td>
                    <td>
                      For powder, enough granules are placed on the anvil to
                      approximately cover an area 1.3 cm (0.5 in) long by 0.65
                      cm (0.25 in) wide starting about 0.65 cm (0.25 in) behind
                      the initial contact point of the wheel with the anvil such
                      that the wheel will be in total contact with the sample
                      when lowered onto it. For solids and semi-solids, the
                      wheel should be in total contact with the sample when
                      lowered onto it.
                    </td>
                  </tr>
                  <tr>
                    <td>Test Temperature and Humidity</td>
                    <td>
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td></td>
                    <td>Standardized as ambient temperature and humidity</td>
                  </tr>
                  <tr>
                    <td>Characterization of reaction types</td>
                    <td>
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td></td>
                    <td>
                      Audible report or production of smoke, fire, charring or
                      visible light
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Adequately determine the relative sensitivity result with
                      the fewest number of trials
                    </td>
                    <td></td>
                    <td>At least 6 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Reaction upon insult</td>
                    <td>Detection of reaction</td>
                    <td>
                      The test result is considered “+” if the lowest friction
                      load at which at least one reaction occurs in six trials
                      is 250 N (56 lbf) at 2.4 m/s (8 ft/s) or 445 N (100 lbf)
                      at 1.2 m/s (4 ft/s) or less and the substance is
                      considered unstable in the form it was tested.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="ABL Friction Setup Example"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/setupabl-min.jpg')"
            >
              <img class="m-auto" src="@/assets/IPSeries/setupabl-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Flash (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128717?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128717"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="Jetting (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128719?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128719"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="No Reaction (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128720?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128720"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
